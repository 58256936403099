import _ from 'lodash';
import { ReactElement, createContext, useEffect, useState } from 'react';

const Cookiebot = (window as any).Cookiebot;

export type CookiebotConsent = {
  marketing: boolean;
  necessary: boolean;
  preferences: boolean;
  statistics: boolean;
} | undefined;

export const CookiebotContext = createContext({
  open: () => {},
  consent: undefined as CookiebotConsent,
});

type CookiebotProviderProps = { children: ReactElement };
export const CookiebotProvider = ({ children }: CookiebotProviderProps) => {
  const [consent, setConsent] = useState<CookiebotConsent>(undefined);

  useEffect(() => {
    window.addEventListener('CookiebotOnAccept', () => {
      setConsent(_.clone(Cookiebot.consent));
    });
    window.addEventListener('CookiebotOnDecline', () => {
      setConsent({ marketing: false, necessary: false, preferences: false, statistics: false });
    });
  }, []);

  const open = () => {
    Cookiebot.renew();
  };

  return (
    <CookiebotContext.Provider value={{ open, consent }}>{children}</CookiebotContext.Provider>
  );
};
