import {
  ButtonTypeMap,
  default as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material/Button';
import { ForwardedRef, useMemo } from 'react';
import { Link, To } from 'react-router-dom';
import { typedForwardRef } from 'src/utils/types/forward-ref';
import { Tooltip } from './Tooltip';

export type ButtonProps<D extends React.ElementType = ButtonTypeMap['defaultComponent']> =
  MuiButtonProps<D, { component?: D }> & {
    tooltip?: string;
    buttonOrLink?: {
      isLink: boolean;
      onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
      to: To | undefined;
    };
  };

export const ButtonInner = <D extends React.ElementType = ButtonTypeMap['defaultComponent']>(
  { variant = 'contained', sx, buttonOrLink, tooltip, ...props }: ButtonProps<D>,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const rest = useMemo(() => {
    if (buttonOrLink) {
      if (buttonOrLink.isLink) {
        return { component: Link, to: buttonOrLink.to };
      } else {
        return { onClick: buttonOrLink.onClick };
      }
    }
    return {};
  }, [buttonOrLink]);

  const button = (
    <MuiButton
      variant={variant}
      sx={{
        textTransform: 'none',
        borderRadius: 25,
        fontSize: variant === 'text' ? 13 : undefined,
        ...sx,
      }}
      {...props}
      {...(rest as Partial<ButtonProps<D>>)}
      ref={ref}
    ></MuiButton>
  );

  return tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button;
};

export const Button = typedForwardRef(ButtonInner);
