import {
  default as MuiImageListItem,
  ImageListItemProps as MuiImageListItemProps,
  ImageListItemTypeMap
} from '@mui/material/ImageListItem';
import { ForwardedRef } from 'react';
import { typedForwardRef } from 'src/utils/types/forward-ref';

export type ImageListItemProps<
  D extends React.ElementType = ImageListItemTypeMap['defaultComponent']
> = MuiImageListItemProps<D, { component?: D }> & {};

export const ImageListItemInner = <
  D extends React.ElementType = ImageListItemTypeMap['defaultComponent']
>(
  { ...props }: ImageListItemProps<D>,
  ref: ForwardedRef<HTMLLIElement>
) => <MuiImageListItem {...props} ref={ref}></MuiImageListItem>;

export const ImageListItem = typedForwardRef(ImageListItemInner);
