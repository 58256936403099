import { AxiosError } from 'axios';
import { useRef } from 'react';
import { MutateOptions, useMutation, UseMutationOptions } from 'react-query';
import axios from 'src/config/axios-config';
import { getFormData } from 'src/utils/http-utils';
import { HttpErrorData } from 'src/utils/types/http-error-data';

const controllers = new Map<string, AbortController>();

export type UseFileUploadQueryParams<TData extends Record<string, unknown>, TResult> = {
  url: string;
  onUploadProgress?: (data: TData, percentage: number) => void;
  options?: UseMutationOptions<TResult, AxiosError<HttpErrorData>, TData>;
};
export const useFileUploadQuery = <TData extends Record<string, unknown>, TResult>({
  url,
  onUploadProgress,
  options,
}: UseFileUploadQueryParams<TData, TResult>) => {
  const onUploadProgressRef = useRef(onUploadProgress);
  onUploadProgressRef.current = onUploadProgress;

  const mutation = useMutation({
    mutationFn: (data: TData & { requestId?: string }) => {
      const controller = new AbortController();
      if (data.requestId) {
        controllers.set(data.requestId, controller);
      }

      const formData = getFormData(data);
      return axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: onUploadProgressRef.current
          ? (event) => {
              const percentage = +((event.loaded / event.total!) * 100).toFixed(0);
              // do not show 100% before file is completely uploaded
              onUploadProgressRef.current!(data, percentage === 100 ? 99 : percentage);
            }
          : undefined,
        signal: controller.signal,
      });
    },
    ...options,
    onSuccess: (data, variables, context) => {
      onUploadProgressRef.current && onUploadProgressRef.current(variables, 100);
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });

  const cancel = (requestId: string) => {
    controllers.get(requestId)!.abort();
  };

  const mutate = (
    data: TData,
    options?: MutateOptions<TResult, AxiosError<HttpErrorData, any>, TData, unknown>,
    requestId?: string
  ) => {
    mutation.mutate({ ...data, requestId }, options);
  };

  return { ...mutation, cancel, mutate };
};
