import { AxiosError, CanceledError } from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { usePushFrontendErrorLog } from 'src/queries/error-log-queries';

export const useErrorHandlingMiddleware = () => {
  const { pushFrontendErrorLog } = usePushFrontendErrorLog();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const _originalConsoleError = console.error
    console.error = (error: unknown) => {
      _originalConsoleError(error)

      if (!(error instanceof AxiosError) || error instanceof CanceledError) return;

      if (!error.response || ![400, 401, 403].includes(error.response.status)) {
        enqueueSnackbar({
          variant: 'error',
          message:
            'Wystąpił błąd podczas próby połączenia z serwerem. Przepraszamy za utrudnienia.',
        });
        // no log here because it should be logged by backend
      }
    };

    window.onerror = (message, stackTrace) => {
      enqueueSnackbar({
        variant: 'error',
        message:
          'Coś poszło nie tak. Wysłaliśmy informację o tym błędzie. Przepraszamy za utrudnienia.',
      });
      pushFrontendErrorLog({ message: typeof message === 'string' ? message : '', stackTrace });
    };
  }, []);
};
