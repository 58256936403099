import {
  default as MuiListItemButton,
  ListItemButtonProps as MuiListItemButtonProps,
  ListItemButtonTypeMap
} from '@mui/material/ListItemButton';
import { ForwardedRef, useMemo } from 'react';
import { Link, To } from 'react-router-dom';
import { typedForwardRef } from 'src/utils/types/forward-ref';

export type ListItemButtonProps<
  D extends React.ElementType = ListItemButtonTypeMap['defaultComponent']
> = MuiListItemButtonProps<D, { component?: D }> & {
  buttonOrLink?: {
    isLink: boolean;
    onClick: React.MouseEventHandler<HTMLElement> | undefined;
    to: To | undefined;
  };
};

export const ListItemButtonInner = <
  D extends React.ElementType = ListItemButtonTypeMap['defaultComponent']
>(
  { buttonOrLink, ...props }: ListItemButtonProps<D>,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const rest = useMemo(() => {
    if (buttonOrLink) {
      if (buttonOrLink.isLink) {
        return { component: Link, to: buttonOrLink.to };
      } else {
        return { onClick: buttonOrLink.onClick };
      }
    }
    return {};
  }, [buttonOrLink]);

  return <MuiListItemButton {...props} ref={ref} {...rest}></MuiListItemButton>;
};

export const ListItemButton = typedForwardRef(ListItemButtonInner);
