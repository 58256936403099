import { Navigate, createBrowserRouter } from 'react-router-dom';
import App from 'src/App';
import { AppRoutes } from 'src/router/app-routes';
import { adminRoutes } from 'src/router/routes/admin-routes';
import { loggedInRoutes } from 'src/router/routes/logged-in-routes';
import { notLoggedInRoutes } from 'src/router/routes/not-logged-in-routes';
import { sharedRoutes } from 'src/router/routes/shared-routes';

export const router = createBrowserRouter([
  {
    path: AppRoutes.Home(),
    element: <App />,
    children: [...sharedRoutes, ...notLoggedInRoutes, ...adminRoutes, ...loggedInRoutes],
  },
  { path: '*', element: <Navigate to={AppRoutes.Home()} /> },
]);
