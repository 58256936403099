import { redirect } from 'react-router-dom';
import { queryClient } from 'src/config/query-config';
import { User, UserRole } from 'src/models/user';
import { getCurrentUserQueryFn, getCurrentUserQueryKey } from 'src/queries/user-queries';
import { AppRoutes } from 'src/router/app-routes';

export const loadCurrentUser = async () => {
  // undefined when user is not yet fetched, null when user is not logged in
  let currentUser: User | null | undefined = queryClient.getQueryData(getCurrentUserQueryKey);
  if (currentUser === undefined) {
    try {
      currentUser = await queryClient.fetchQuery(getCurrentUserQueryKey, getCurrentUserQueryFn);
    } catch {
      console.log('error loading current user');
    }
  }
  return currentUser;
};

export const adminLoader = async () => {
  const protectedLoaderResult = await loggedInLoader();
  if ('status' in protectedLoaderResult) return protectedLoaderResult;

  return protectedLoaderResult.role === UserRole.Admin ? null : redirect(AppRoutes.Home());
};
export const loggedInLoader = async () => {
  const currentUser = await loadCurrentUser();
  return currentUser
    ? currentUser
    : redirect(AppRoutes.Login({ redirectUrl: location.pathname + location.search }));
};
export const notLoggedInLoader = async () => {
  const currentUser = await loadCurrentUser();
  return currentUser ? redirect(AppRoutes.Home()) : null;
};
