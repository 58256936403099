import data from '@emoji-mart/data/sets/15/google.json';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { init } from 'emoji-mart';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import { queryClient } from 'src/config/query-config';
import { snackbarConfig } from 'src/config/snackbar-config';
import { theme } from 'src/config/theme-config';
import { ConfirmationDialogProvider } from 'src/providers/ConfirmationDialogProvider/ConfirmationDialogProvider';
import { CookiebotProvider } from 'src/providers/CookiebotProvider/CookiebotProvider';
import { router } from 'src/router/router';
import { environment } from 'src/utils/environment-utils';
import './config/axios-config';
import './index.css';
import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_GOOGLE_MEASUREMENT_ID && environment.name === 'prod') {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_MEASUREMENT_ID);
}

init({ data });

dayjs.extend(utc);
dayjs.extend(timezone);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pl'>
        <SnackbarProvider {...snackbarConfig}>
          <CookiebotProvider>
            <ThemeProvider theme={theme}>
              <ConfirmationDialogProvider>
                <RouterProvider router={router} />
              </ConfirmationDialogProvider>
            </ThemeProvider>
          </CookiebotProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
