import _ from 'lodash';
import { useContext, useEffect } from 'react';
import { CookiebotContext } from 'src/providers/CookiebotProvider/CookiebotProvider';
import { useGetCurrentUser, useSendUserActivityTick } from 'src/queries/user-queries';

export const useUserActivityWatcher = () => {
  const { sendUserActivityTick } = useSendUserActivityTick();
  const { currentUser } = useGetCurrentUser();
  const { consent } = useContext(CookiebotContext);

  useEffect(() => {
    if (!currentUser || currentUser.privacySettings!.isActiveStatusHidden) return;

    const handler = _.throttle(
      () => {
        if (consent?.preferences) {
          sendUserActivityTick();
        }
      },
      15000,
      { trailing: false }
    );

    const events = ['mousedown', 'mousemove', 'touchstart', 'scroll', 'keydown'];
    events.forEach((event) => {
      document.addEventListener(event, handler, { capture: true });
    });
    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, handler, { capture: true });
      });
    };
  }, [currentUser, consent?.preferences]);
};
