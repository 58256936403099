import {
  default as MuiTypography,
  TypographyProps as MuiTypographyProps,
  TypographyTypeMap,
} from '@mui/material/Typography';
import { ForwardedRef } from 'react';
import { typedForwardRef } from 'src/utils/types/forward-ref';

export type TypographyProps<D extends React.ElementType = TypographyTypeMap['defaultComponent']> =
  MuiTypographyProps<D, { component?: D }> & {};

export const TypographyInner = <
  D extends React.ElementType = TypographyTypeMap['defaultComponent']
>(
  { ...props }: TypographyProps<D>,
  ref: ForwardedRef<HTMLElement>
) => <MuiTypography {...props} ref={ref}></MuiTypography>;

export const Typography = typedForwardRef(TypographyInner);
