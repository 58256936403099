import { Box, BoxProps } from './Box';

export type ImageName =
  | 'logo-icon.svg'
  | 'logo-text-white.svg'
  | 'logo-text-black.svg'
  | 'logo-text-preview.png'
  | 'people.jpg'
  | 'anonymous.png'
  | 'open-external-step-1.jpg'
  | 'open-external-step-2.jpg';

const alts = new Map<ImageName, string>([
  ['logo-icon.svg', 'Logo ikona'],
  ['logo-text-white.svg', 'Logo tekstowe białe'],
  ['logo-text-black.svg', 'Logo tekstowe czarne'],
  ['logo-text-preview.png', 'Logo Meetyo do podglądu'],
  ['people.jpg', 'Ludzie'],
  ['anonymous.png', 'Ikona anonimowej osoby'],
  ['open-external-step-1.jpg', 'Instrukcja otwierania w zewnętrznej przeglądarce - krok 1'],
  ['open-external-step-2.jpg', 'Instrukcja otwierania w zewnętrznej przeglądarce - krok 2'],
]);

export type StaticImageProps = {
  name: ImageName;
} & Omit<BoxProps<'img'>, 'ref'>;

export const StaticImage = ({ name, ...props }: StaticImageProps) => {
  return (
    // from public folder
    <Box
      component={'img'}
      maxWidth={'100%'}
      maxHeight={'100%'}
      src={`/images/${name}`}
      alt={alts.get(name)}
      {...props}
    />
  );
};
