import _ from 'lodash';
import { Announcement, AnnouncementStatus } from 'src/models/annoucement/announcement';
import { UserChatType } from 'src/models/chat/chat';
import { ResultListFilters } from 'src/utils/announcement-result-list-utils';
import { parseIntoURLParams } from 'src/utils/url-utils';

export const privateChatUrl = '/my-chats/private-chat';
export const groupChatUrl = '/my-chats/group-chat';

export const AppRoutes = {
  Home: (params?: HomeParams) => makeUrl('/', params),
  Login: (params?: LoginParams) => makeUrl('/login', params),
  Register: (params?: RegisterParams) => makeUrl('/register', params),
  RegisterRegular: (params?: RegisterParams) => makeUrl('/register/regular', params),
  RegisterExternal: (params?: RegisterParams) => makeUrl('/register/external', params),
  ForgotPassword: () => '/forgot-password',
  ResetPassword: (params: ResetPasswordParams) => makeUrl('/reset-password', params),
  AnnouncementResultList: (params: ResultListFilters) =>
    makeUrl('/announcement-result-list', parseIntoURLParams(params)),
  MyAnnouncements: () => '/my-announcements',
  MyAnnouncementsList: (params: MyAnnouncementsListParams) =>
    makeUrl('/my-announcements/list', params),
  NewAnnouncement: (params?: NewAnnouncementParams) =>
    makeUrl('/my-announcements/new-announcement', params),
  EditAnnouncement: (params: EditAnnouncementParams) =>
    makeUrl('/my-announcements/edit-announcement', params),
  Settings: () => '/settings',
  SettingsChangeData: () => '/settings/change-data',
  SettingsChangePassword: () => '/settings/change-password',
  SettingsChangeEmail: () => '/settings/change-email',
  SettingsConnectedAccounts: () => '/settings/connected-accounts',
  SettingsPrivacy: () => '/settings/change-privacy',
  SettingsEmailNotifications: () => '/settings/email-notifications',
  MyChats: (params: MyChatsParams) => makeUrl('/my-chats', params),
  PrivateChat: (params: PrivateChatParams) => makeUrl(privateChatUrl, params),
  GroupChat: (params: GroupChatParams) => makeUrl(groupChatUrl, params),
  NewGroupChat: () => '/new-group-chat',
  UserProfile: (params: UserProfileParams) => makeUrl('/user-profile', params),
  ObservedSearches: () => '/observed-searches',
  AdminPanel: () => '/admin-panel',
  AdminPanelPendingAnnouncements: () => '/admin-panel/pending-announcements',
  AdminPanelFeedback: () => '/admin-panel/feedback',
  AdminPanelViolationReports: () => '/admin-panel/violation-reports',
  AdminPanelErrorLogs: () => '/admin-panel/error-logs',
  AdminPanelAdvertisement: () => '/admin-panel/advertisement',
  AdminPanelUsers: () => '/admin-panel/users',
  Notifications: () => '/notifications',
  Contact: (params?: ContactParams) => makeUrl('/contact', params),
  PrivacyPolicy: () => '/privacy-policy',
  TermsOfService: () => '/terms-of-service',
  NewAnnouncementPublic: (params?: NewAnnouncementParams) =>
    makeUrl('/new-announcement-public', params),
} as const;

export type HomeParams = {
  isFromChangeEmailAddress?: 'true';
};

export type EditAnnouncementParams = {
  id: string;
};

export type ResetPasswordParams = {
  key: string;
};

export type LoginParams = {
  isFromResetPassword?: 'true';
  redirectUrl?: string;
  announcementId?: string;
};

export type RegisterParams = {
  redirectUrl?: string;
  announcementId?: string;
};

export type MyAnnouncementsListParams = {
  status: keyof typeof AnnouncementStatus;
  announcementId?: Announcement['id'];
  isFromRegister?: 'true';
};

export type PrivateChatParams = {
  userId: string;
  announcementId?: string;
} & MyChatsParams;

export type GroupChatParams = {
  chatId: string;
  returnUrl?: string;
} & MyChatsParams;

export type MyChatsParams = {
  type?: keyof typeof UserChatType;
};

export type UserProfileParams = {
  userId: string;
};

export type ContactParams = {
  newCategoryProposal?: string;
};

export type NewAnnouncementParams = {
  hasPromotion?: 'true';
};

const makeUrl = (url: string, params?: Record<string, string>) => {
  if (!params || _.isEmpty(params)) return url;

  Object.entries(params).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      delete params[key];
    }
  });

  const query = new URLSearchParams(params).toString();
  return `${url}?${query}`;
};
