import { UserLocation } from 'src/models/user-location';

export enum UserGender {
  Male,
  Female,
}
export enum UserActiveStatus {
  Online,
  RecentlyOnline,
  Offline,
}
export enum UserRole {
  User,
  Admin,
}

export type UserPrivacySettings = {
  isBirthDateHidden: boolean;
  isLocationHidden: boolean;
  isActiveStatusHidden: boolean;
};

export type UserEmailNotificationSettings = {
  isEmailAllDisabled: boolean;
  isEmailNotificationAboutMessageEnabled: boolean;
  isEmailNotificationAboutGroupEnabled: boolean;
};

export type UserExternalAuthSettings = {
  facebookId: string | null;
  googleId: string | null;
};

export type User = {
  id: string;
  createdAt: string | null;
  isDelete: boolean;
  email: string;
  password: string | null;
  firstName: string;
  lastName: string;
  birthDate: string | null;
  passwordRepeat: string | null;
  gender: UserGender | null;
  isConfirmed: boolean | null;
  profileImageSrc: string | null;
  activeStatus: UserActiveStatus | null;
  role: UserRole;
  newNotificationsCount: number;
  locationId: UserLocation['id'] | null;
  // will have value for currentUser
  hasPassword: boolean | null;
  privacySettings: UserPrivacySettings | null;
  emailNotificationSettings: UserEmailNotificationSettings | null;
  externalAuthSettings: UserExternalAuthSettings | null;
};
