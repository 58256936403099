import { useContext, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import { ErrorFallback } from 'src/components/ErrorFallback';
import { useErrorHandlingMiddleware } from 'src/hooks/useErrorHandlingMiddleware';
import { useUserActivityWatcher } from 'src/hooks/useUserActivityWatcher';
import { CookiebotContext } from 'src/providers/CookiebotProvider/CookiebotProvider';
import { InfoDialogProvider } from 'src/providers/InfoDialogProvider/InfoDialogProvider';
import { LoginRequiredDialogProvider } from 'src/providers/LoginRequiredDialogProvider/LoginRequiredDialogProvider';
import { environment } from 'src/utils/environment-utils';

function App() {
  const { consent } = useContext(CookiebotContext);
  useUserActivityWatcher();
  useErrorHandlingMiddleware();

  useEffect(() => {
    // if (consent?.statistics && process.env.REACT_APP_GOOGLE_MEASUREMENT_ID && environment.name === 'prod') {
    if (process.env.REACT_APP_GOOGLE_MEASUREMENT_ID && environment.name === 'prod') {
      // in index.tsx
      // ReactGA.initialize(process.env.REACT_APP_GOOGLE_MEASUREMENT_ID);
    }
    // }, [consent?.statistics]);
  }, []);

  return (
    // ErrorBoundary and provider must be here
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <InfoDialogProvider>
        <LoginRequiredDialogProvider>
          <Outlet />
        </LoginRequiredDialogProvider>
      </InfoDialogProvider>
    </ErrorBoundary>
  );
}

export default App;
