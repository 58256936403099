export const getFormData = (data: Record<string, unknown>) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (value === null || value === undefined) return;
    formData.append(key, value as string | Blob);
  });

  return formData;
};
