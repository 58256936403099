import { createContext, ReactNode, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from 'src/ui-components';
import { PropsWithReactElement } from 'src/utils/types/props';

export type InfoDialogData = {
  info: ReactNode;
};
export const InfoDialogContext = createContext({
  showInfoDialog: (data: InfoDialogData) => {},
});

export type InfoDialogProviderProps = PropsWithReactElement;

export const InfoDialogProvider = ({ children }: InfoDialogProviderProps) => {
  const [dialog, setDialog] = useState<InfoDialogData | null>(null);

  const onClose = () => {
    setDialog(null);
  };

  return (
    <>
      <InfoDialogContext.Provider
        value={{ showInfoDialog: (data) => setDialog(data) }}
      >
        {children}
      </InfoDialogContext.Provider>
      {dialog && (
        <Dialog open onClose={onClose} sx={{ textAlign: 'center' }}>
          <DialogTitle>Informacja</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialog.info}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant='text'>
              Zamknij
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
