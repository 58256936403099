import { RouteObject } from 'react-router-dom';
import { AppRoutes } from 'src/router/app-routes';
import { loggedInLoader } from 'src/router/loaders';

export const loggedInRoutes: RouteObject[] = [
  {
    path: AppRoutes.MyChats({} as any),
    lazy: async () => {
      const { MyChats } = await import('../../pages/chat/MyChats/responsive/MyChats');
      return { Component: MyChats };
    },
    loader: loggedInLoader,
    children: [
      {
        path: AppRoutes.PrivateChat({} as any),
        lazy: async () => {
          const { PrivateChat } = await import('../../pages/chat/PrivateChat/PrivateChat');
          return { Component: PrivateChat };
        },
        loader: loggedInLoader,
      },
      {
        path: AppRoutes.GroupChat({} as any),
        lazy: async () => {
          const { GroupChat } = await import('../../pages/chat/GroupChat/GroupChat');
          return { Component: GroupChat };
        },
        loader: loggedInLoader,
      },
    ],
  },
  {
    path: AppRoutes.NewGroupChat(),
    lazy: async () => {
      const { NewGroupChat } = await import('../../pages/chat/NewGroupChat/NewGroupChat');
      return { Component: NewGroupChat };
    },
    loader: loggedInLoader,
  },
  {
    path: AppRoutes.MyAnnouncements(),
    lazy: async () => {
      const { MyAnnouncements } = await import(
        '../../pages/announcement/MyAnnouncements/responsive/MyAnnouncements'
      );
      return { Component: MyAnnouncements };
    },
    loader: loggedInLoader,
    children: [
      {
        path: AppRoutes.MyAnnouncementsList({} as any),
        lazy: async () => {
          const { MyAnnouncementsList } = await import(
            '../../pages/announcement/MyAnnouncements/sub-pages/MyAnnouncementsList/MyAnnouncementsList'
          );
          return { Component: MyAnnouncementsList };
        },
        loader: loggedInLoader,
      },
      {
        path: AppRoutes.NewAnnouncement(),
        lazy: async () => {
          const { NewAnnouncement } = await import(
            '../../pages/announcement/MyAnnouncements/sub-pages/NewAnnouncement/NewAnnouncement'
          );
          return { Component: NewAnnouncement };
        },
        loader: loggedInLoader,
      },
      {
        path: AppRoutes.EditAnnouncement({} as any),
        lazy: async () => {
          const { EditAnnouncement } = await import(
            '../../pages/announcement/MyAnnouncements/sub-pages/EditAnnouncement/EditAnnouncement'
          );
          return { Component: EditAnnouncement };
        },
        loader: loggedInLoader,
      },
    ],
  },
  {
    path: AppRoutes.ObservedSearches(),
    lazy: async () => {
      const { ObservedSearches } = await import(
        '../../pages/account/ObservedSearches/ObservedSearches'
      );
      return { Component: ObservedSearches };
    },
    loader: loggedInLoader,
  },
  {
    path: AppRoutes.Notifications(),
    lazy: async () => {
      const { Notifications } = await import('../../pages/user/Notifications/Notifications');
      return { Component: Notifications };
    },
    loader: loggedInLoader,
  },
  {
    path: AppRoutes.Settings(),
    lazy: async () => {
      const { Settings } = await import('../../pages/account/Settings/responsive/Settings');
      return { Component: Settings };
    },
    loader: loggedInLoader,
    children: [
      {
        path: AppRoutes.SettingsChangeData(),
        lazy: async () => {
          const { SettingsChangeData } = await import(
            '../../pages/account/Settings/sub-pages/SettingsChangeData'
          );
          return { Component: SettingsChangeData };
        },
        loader: loggedInLoader,
      },
      {
        path: AppRoutes.SettingsChangePassword(),
        lazy: async () => {
          const { SettingsChangePassword } = await import(
            '../../pages/account/Settings/sub-pages/SettingsChangePassword'
          );
          return { Component: SettingsChangePassword };
        },
        loader: loggedInLoader,
      },
      {
        path: AppRoutes.SettingsConnectedAccounts(),
        lazy: async () => {
          const { SettingsConnectedAccounts } = await import(
            '../../pages/account/Settings/sub-pages/SettingsConnectedAccounts'
          );
          return { Component: SettingsConnectedAccounts };
        },
        loader: loggedInLoader,
      },
      {
        path: AppRoutes.SettingsChangeEmail(),
        lazy: async () => {
          const { SettingsChangeEmail } = await import(
            '../../pages/account/Settings/sub-pages/SettingsChangeEmail'
          );
          return { Component: SettingsChangeEmail };
        },
        loader: loggedInLoader,
      },
      {
        path: AppRoutes.SettingsPrivacy(),
        lazy: async () => {
          const { SettingsPrivacy } = await import(
            '../../pages/account/Settings/sub-pages/SettingsPrivacy'
          );
          return { Component: SettingsPrivacy };
        },
        loader: loggedInLoader,
      },
      {
        path: AppRoutes.SettingsEmailNotifications(),
        lazy: async () => {
          const { SettingsEmailNotifications } = await import(
            '../../pages/account/Settings/sub-pages/SettingsEmailNotifications'
          );
          return { Component: SettingsEmailNotifications };
        },
        loader: loggedInLoader,
      },
    ],
  },
];
