import { RouteObject } from 'react-router-dom';
import { AppRoutes } from 'src/router/app-routes';

export const sharedRoutes: RouteObject[] = [
  {
    index: true,
    lazy: async () => {
      const { Home } = await import('../../pages/Home/responsive/Home');
      return { Component: Home };
    },
    loader: () => null,
  },
  {
    path: AppRoutes.AnnouncementResultList({} as any),
    lazy: async () => {
      const { AnnouncementResultList } = await import(
        '../../pages/announcement/AnnouncementResultList/responsive/AnnouncementResultList'
      );
      return { Component: AnnouncementResultList };
    },
    loader: () => null,
  },
  {
    path: AppRoutes.UserProfile({} as any),
    lazy: async () => {
      const { UserProfile } = await import('../../pages/user/UserProfile/UserProfile');
      return { Component: UserProfile };
    },
    loader: () => null,
  },
  {
    path: AppRoutes.Contact(),
    lazy: async () => {
      const { Contact } = await import('../../pages/Contact/Contact');
      return { Component: Contact };
    },
    loader: () => null,
  },
  {
    path: AppRoutes.PrivacyPolicy(),
    lazy: async () => {
      const { PrivacyPolicy } = await import('../../pages/PrivacyPolicy/PrivacyPolicy');
      return { Component: PrivacyPolicy };
    },
    loader: () => null,
  },
  {
    path: AppRoutes.TermsOfService(),
    lazy: async () => {
      const { TermsOfService } = await import('../../pages/TermsOfService/TermsOfService');
      return { Component: TermsOfService };
    },
    loader: () => null,
  },
];
