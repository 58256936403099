import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import axios from 'src/config/axios-config';
import { ErrorLog } from 'src/models/error-log';
import { environment } from 'src/utils/environment-utils';
import { genericUseMutationMethods } from 'src/utils/types/generic-query-methods';
import { HttpErrorData } from 'src/utils/types/http-error-data';

const baseUrl = `${environment.apiUrl}/ErrorLog`;

export const usePushFrontendErrorLog = (
  options?: UseMutationOptions<
    void,
    AxiosError<HttpErrorData>,
    { message: ErrorLog['message']; stackTrace: ErrorLog['stackTrace'] | undefined }
  >
) => {
  const mutation = useMutation({
    mutationFn: (data) => axios.post(`${baseUrl}/PushFrontendErrorLog`, data),
    ...options,
  });

  return genericUseMutationMethods('pushFrontendErrorLog', mutation);
};
